<!--
MAP
-->
<template>
    <!--map-->
    <div class="container-fluid px-4 py-4 my-4 map">
      <div class="container">
        <div class="col-lg-3">
          <div class="map-title text-yellow fw-bold py-1"><font-awesome-icon :icon="['fas', 'chevron-circle-right']"/> 1101 Budapest, Expo tér 5-7.</div>
          <div class="map-list text-white px-4">
            10kW/rack teljesítmény<br>
            Vállalt rendelkezésre állás: 99.999%<br>
            IP Tranzit Uplinkek: Telia, GTT<br>
            Zártláncú video megfigyelés<br>
            PCI DSS szabvány megfelelőség<br>
            Aspirációs VESDA tűzjelző rendszer<br>
            Freecooling képes kültéri folyadékhűtők, trópusi üzemmódra átépítve<br>
          </div>
          <div class="map-title text-yellow fw-bold py-1"><font-awesome-icon :icon="['fas', 'chevron-circle-right']"/> 1132 Budapest, Viktor Hugo utca 18-22.</div>
          <div class="map-list text-white px-4">
            10g uplink<br>
            Gigabites polcok, salgó és rack hosting<br>
          </div>
          <div class="map-title text-yellow fw-bold py-1"><font-awesome-icon :icon="['fas', 'chevron-circle-right']"/> 1139 Budapest, Váci út 99-105. Balance Building ép. 3. em.</div>
          <div class="map-list text-white px-4">
          </div>
        </div>
        </div>
    </div>
    <!--map-->
</template>

<script>
export default {
  name: "module11",
}
</script>

<style scoped>

</style>